const $ = require('jquery');
global.$ = global.jQuery = $;

import ReactDom from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import UIkit from 'uikit';
import React from "react";
import UpdateTraffic from "../../react/traffic/UpdateTraffic";

import ResumedPlan from "../../../../assets/bleucitron/react/front/resume/ResumedPlan";


require('../../css/front/magnific-popup.css');
require('../../css/front/owl.carousel.min.css');
require('../../css/front/pe-icon-7-stroke.css');
require('../../css/front/meanmenu.min.css');
require('../../css/front//jquery-ui.css');
require('../../css/front/bundle.css');
require('../../css/front/bootstrap-datepicker.css');
require('../../css/front/bootstrap-multiselect.min.css');
require('../../css/front/style.css');
require('../../css/front/responsive.css');
require('../../css/front/bcb.css');
require( '../../../common/css/_map.scss');

require('./timer.js');
require('jquery-ui/ui/widgets/datepicker');


$(document).ready(function(){

    UpdateTraffic();

    /* DATEPICKER */

    ( function( factory ) {
        if ( typeof define === "function" && define.amd ) {

            // AMD. Register as an anonymous module.
            define( [ "jquery-ui/ui/widgets/datepicker" ], factory );
        } else {

            // Browser globals
            factory( jQuery.datepicker );
        }
    }( function( datepicker ) {

        datepicker.regional.fr = {
            closeText: "Fermer",
            prevText: "Précédent",
            nextText: "Suivant",
            currentText: "Aujourd'hui",
            monthNames: [ "janvier", "février", "mars", "avril", "mai", "juin",
                "juillet", "août", "septembre", "octobre", "novembre", "décembre" ],
            monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin",
                "juil.", "août", "sept.", "oct.", "nov.", "déc." ],
            dayNames: [ "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi" ],
            dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
            dayNamesMin: [ "D","L","M","M","J","V","S" ],
            weekHeader: "Sem.",
            dateFormat: "dd/mm/yy",
            firstDay: 1,
            isRTL: false,
            showMonthAfterYear: false,
            yearSuffix: ""
        };
        datepicker.setDefaults( datepicker.regional.fr );

        return datepicker.regional.fr;

    } ));

    var getDaysArray = function(s,e) {for(var a=[],d=s;d<=e;d.setDate(d.getDate()+1)){ a.push((new Date(d)).toISOString().substr(0,10));}return a;};


    $('#modal-datepicker').datepicker();

    $('#event-datepicker').each(function() {

        var from = $(this).attr('data-calendar-from');
        var to = $(this).attr('data-calendar-to');
        var init = $(this).attr('data-calendar-init');

        var daylist = getDaysArray(new Date(from),new Date(to));

        $(this).datepicker({
            defaultDate: init ? new Date(init) : new Date(from),
            // gotoCurrent: true,
            beforeShowDay:function(date){
                var day = date.getDay();
                var string = $.datepicker.formatDate('yy-mm-dd', date);
                var isDisabled = ($.inArray(string, daylist) == -1);

                //day != 0 disables all Sundays
                return [!isDisabled];
            },
            onSelect: function(dateText, inst) {

                // var date = $(this).datepicker('getDate');

                if (hasManySessions(dateText)) {
                    showHours(dateText);
                }
                else {
                    redirectToDate(dateText)
                }

            }
        });

    });

    $('[data-timetable-link]').click(function() {
        window.location = $(this).attr('data-timetable-link') + '?calendar=1';
    });



    function hasManySessions(frDate) {

        return $('[data-timetable-date="'+frDate+'"] [data-timetable-link]').length > 1;

    }

    function showHours(frDate) {
        var $datepicker = $('#event-datepicker');
        var $timetable = $('[data-timetable-date="'+frDate+'"]');

        // $('#availibility-card-date').text(frDate);
        $timetable.removeClass('d-none');
        $datepicker.addClass('d-none');
    }

    function redirectToDate(frDate) {
        var $checkbox = $('[data-timetable-date="'+frDate+'"] [data-timetable-link]:first');
        if ($checkbox.length) {
            window.location = $checkbox.attr('data-timetable-link') + '?calendar=1';
        }
    }

    /* RETURN TO DATEPICKER TO CHOSE ANOTHER DATE (PAGE EVENT) */

    $('#datepicker-return').on('click', function(e) {
        e.preventDefault();
        $('#event-datepicker').removeClass('d-none');
        $('#event-timetable').addClass('d-none');
    });


    /* REMOVE ANIMATIONS WHEN DATEPICKER ACCORDION IS CLOSED (PAGE EVENT) */

    /*$('.availibility-card-title').on('click', function() {
        $('#event-timetable').removeClass('uk-animation-slide-left').removeClass('uk-animation-slide-right');
        $('#event-datepicker').removeClass('uk-animation-slide-left').removeClass('uk-animation-slide-right');
    });*/


    /* CLOSE ACCORDION WHEN TIME IS CHOSEN AND GET DATE + TIME ON ACCORDION TITLE (PAGE EVENT) */

    $('#event-timetable input').on('change', function() {
        var timetable = $(this).val();
        var date = $('#event-datepicker').datepicker('getDate');
        var longDate = $.datepicker.formatDate('DD dd M yy', date);
        UIkit.accordion($('.uk-accordion')).toggle($('.uk-accordion li'), true);
        $('.uk-accordion-title').text(longDate + ' - ' + timetable);
    });


    /* PERMET DE METTRE LE BON ICON EN FONCTION DE L'OUVERTURE/FERMETURE DE L'ACCORDÉON DES CATÉGORIES */
    $('#accordion-event').each(function() {
       $(this).find('.card-header .btn').click(function() {
           if ($(this).attr('aria-expanded') === 'false') {
               $(this).find('i').removeClass('fa-angle-down').addClass('fa-angle-up');
           } else {
               $(this).find('i').removeClass('fa-angle-up').addClass('fa-angle-down');
           }
       });
    });


    /* SCROLL AUTO SI UN ATTRIBUT data-move-to EST PRESENT */

    var $moveTo = $('[data-move-to]');
    if ($moveTo.length) {
        $('html, body').animate({scrollTop: $moveTo.offset().top}, 800);
    }



    /* CHANGEMENT DE DATE SUR EVENT MULTISEANCE */

    var $selectSessions = $('.select_event_sessions');

    if ($selectSessions.length) {

        $selectSessions.change(function () {

            $('#loader_switch_session').show();
            window.location = $(this).val();

        });

    }


    //
    $('[data-modal="show"]').modal('show');


    /* PERMET D'AJUSTER LA HAUTEUR DU CONTENT EN FONCTION DU HEADER */
    $(window).scroll(function(){
        if ($('.header-top').hasClass('stick')) {
            $('.content').addClass('stick');
        } else {
            $('.content').removeClass('stick');
        }
    });


    //
    let recaptcha = document.getElementById('recaptcha');
    if (recaptcha) {
        let isCaptchaOk = false;
        function onChange(value) {
            isCaptchaOk = value;
            document.getElementById('login-submit').removeAttribute('disabled');
        }

        ReactDom.render(
            <ReCAPTCHA sitekey="6LdwQ5waAAAAAOJPj69w9B5O0pR3Mq94jkn3N3OX" onChange={onChange} />,
            recaptcha
        );

        document.forms['login-form'].addEventListener('submit',function(e){
            e.preventDefault();
            if (isCaptchaOk) {
                this.submit();
            }
        })
    }


    var popins = document.querySelectorAll("[data-popin]");
    Array.prototype.forEach.call(popins, function(popin_el, i){

        UIkit.util.on('#' + popin_el.getAttribute('id'), 'show', function () {
            document.cookie = popin_el.getAttribute('id') + '=' + popin_el.getAttribute('data-popin') + ';path=/;';
        });

        let cookieValue = popin_el.getAttribute('data-popin');
        let cookieName = popin_el.getAttribute('id');
        let regex = new RegExp(`(^| )${cookieName}=([^;]+)`)
        let cookie = document.cookie.match(regex);

        if (!cookie || (cookie[2] !== cookieValue)) {
            UIkit.modal('#' + popin_el.getAttribute('id')).show();
        }

    });


/***************************************************************/
/** GESTION DU CHOIX SUR PLAN                                 **/
/***************************************************************/

    var mapTnl = document.querySelector("#availabilityMap");
    var mapJs = document.querySelector("#map-js");

    if (mapJs) {

        ReactDom.render(
            <ResumedPlan
                sessionId={window.MAP_PROPS.sessionId}
                sessionList={window.MAP_PROPS.sessionList}
                idwl={window.MAP_PROPS.wlId}
                maxSeats={window.MAP_PROPS.maxSeats}
                holders={window.MAP_PROPS.holders}
                onBooked={onBooked}
                onSeatSelected={onSeatSelected}
            />,
            mapTnl
        );

        $('#availabilityMap').on('shown.bs.modal', function () {
            ReactDom.render(
                <ResumedPlan
                    sessionId={window.MAP_PROPS.sessionId}
                    sessionList={window.MAP_PROPS.sessionList}
                    idwl={window.MAP_PROPS.wlId}
                    maxSeats={window.MAP_PROPS.maxSeats}
                    holders={window.MAP_PROPS.holders}
                    onBooked={onBooked}
                    onSeatSelected={onSeatSelected}
                    autoload={true}
                />,
                mapTnl
            );
        });
    }


});

function onBooked(res) {
    if (res.hasOwnProperty("error")) {
        createErrorModal(res.typeError || "Une erreur s'est produite");
    } else {
        window.location = window.MAP_PROPS.cartUrl;
    }
}

function createErrorModal(errorMessage) {
    let modalOverlay = document.createElement("div");
    modalOverlay.style.position = "fixed";
    modalOverlay.style.top = "0";
    modalOverlay.style.left = "0";
    modalOverlay.style.width = "100%";
    modalOverlay.style.height = "100%";
    modalOverlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    modalOverlay.style.display = "flex";
    modalOverlay.style.alignItems = "center";
    modalOverlay.style.zIndex = "99999";
    modalOverlay.style.justifyContent = "center";
    modalOverlay.id = "modalOverlay";

    let modalContainer = document.createElement("div");
    modalContainer.style.backgroundColor = "#ffffff";
    modalContainer.style.color = "#000000";
    modalContainer.style.padding = "20px";
    modalContainer.style.border = "1px solid #f5c6cb";
    modalContainer.style.borderRadius = "5px";
    modalContainer.style.width = "300px";
    modalContainer.style.textAlign = "center";

    let modalMessage = document.createElement("p");
    modalMessage.innerHTML = errorMessage; // Utiliser innerHTML pour afficher le HTML

    let closeButton = document.createElement("button");
    closeButton.innerText = "Fermer";
    closeButton.style.marginTop = "15px";
    closeButton.onclick = closeModal;

    modalContainer.appendChild(modalMessage);
    modalContainer.appendChild(closeButton);
    modalOverlay.appendChild(modalContainer);
    document.body.appendChild(modalOverlay);
}

function closeModal() {
    let modalOverlay = document.getElementById("modalOverlay");
    if (modalOverlay) {
        document.body.removeChild(modalOverlay);
    }
}


function onSeatSelected() {

    var $overlay = $('[data-overlay="loader"]');
    $overlay.addClass('uk-hidden');
    $('.map-btn-addCart-js').removeClass('disabled');

}

