import React, {useState, useEffect, useRef} from "react";


// funct component qui récupère la props session ID
const ResumeSeat = ({seat, onDelete}) => {

    const handleUnselect = () => {
        onDelete(seat.id);
    }

    return (

        <div className={"panel-group"} data-id={seat.id}>
            <div className={"panel panel-default panel-place"}style={{'border':'0px'}}>
                <div className={""}>
                    <ul className={"actions list-inline pull-right"}>
                        <li><a className={"btn btn-xs action _deletePlaceLink"} style={{'padding': '0'}} title="Supprimer" onClick={handleUnselect}><span className={"icon"} ><i className={"fas fa-trash"}></i></span></a></li>
                    </ul>
                    <h4 className={"panel-title heading-box"} ><span className={"text"} style={{'display': 'flex', 'flexDirection':'column'}}><strong className={"category"}>{seat.category}</strong><span className={"zone"}>{seat.area}</span></span></h4>
                </div>
                <div className={"panel-body"} style={{'padding':'10px 0px'}}>
                    <div className={"table-responsive"}>
                        <table className={"table table-condensed table-ticket"} style={{'borderTop': '4px solid','borderTopColor': seat.color}}>
                            <colgroup>
                                <col width="33%" />
                                <col width="33%" />
                                <col width="33%" />
                            </colgroup>
                            <thead>
                            <tr>
                                <th className={"access"} style={{'textAlign':'center', 'border' :'1px solid #E6E6E6'}}>Rang</th>
                                <th className={"access"} style={{'textAlign':'center', 'border' :'1px solid #E6E6E6'}}>Siège</th>
                                <th className={"access"} style={{'textAlign':'center', 'border' :'1px solid #E6E6E6'}}>Type</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td className={"row"} style={{'textAlign':'center', 'border' :'1px solid #E6E6E6'}}>{seat.row}</td>
                                <td className={"seatNumber"} style={{'textAlign':'center', 'border' :'1px solid #E6E6E6'}}>{seat.seat}</td>
                                <td className={"seatType"} style={{'textAlign':'center', 'border' :'1px solid #E6E6E6'}}>{seat.typeLabel}</td>
                            </tr>
                            </tbody>
                            <tfoot style={{'borderTop': '4px solid','borderTopColor': '#E6E6E6'}}>
                            <tr style={{'border': '1px solid #E6E6E6'}} >
                                <th className={"tariff"} colSpan="2"><span style={{'marginLeft' :'10px'}}>{seat.priceName}</span>
                                </th>
                                <td className={"price"} style={{'textAlign':'right'}}><span style={{'marginRight' :'10px'}}>{seat.priceLabel}</span></td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default ResumeSeat;
