import React, { useEffect, useState } from 'react';
import {displayTimer, fetchCart} from "../timer";

const Cart = ({cartSummaryUrl}) => {

    const [cart, setCart] = useState(false);

    useEffect( () => {
        (async () => {
            const cart = await fetchCart();
            setCart(cart);
        })();
    }, []);

    useEffect(() => {
        if (cart) {
            displayTimer();
        }
    }, [cart]);

    return (
        <>
            <div className="header-cart common-style cart-icon-container">
                <a className="icon-cart" href={cartSummaryUrl}>
                    <i className="fa fa-shopping-basket">
                        {cart && cart.totalQuantity > 0 ?
                            <span className="shop-count">{cart.totalQuantity}</span>
                        :
                            <></>
                        }
                    </i>
                </a>
            </div>
            {cart && cart.totalQuantity > 0 ?
                <div className="header-cart common-style cart-countdown-container">
                    <div data-toggle="tooltip" data-placement="top" title="Votre panier est limité dans le temps" className="timer-cart"
                         data-timer="countdown" data-tare={cart.nowDate}
                         data-end={cart.expirationDate}><i className="fas fa-stopwatch"></i></div>
                </div>
            :
                <></>
            }
        </>
    )
};


export default Cart;
