import $ from 'jquery';
import 'bootstrap';
require('../../../common/libs/jquery.syotimer.min');

import ReactDOM from 'react-dom';
import React from 'react';
import Cart from './components/Cart';

$(document).ready(function() {
    const cartContainer = document.getElementById('js-cart');
    const cartSummaryUrl = cartContainer.getAttribute('data-url-cart-summary');

    ReactDOM.render(
        <Cart cartSummaryUrl={cartSummaryUrl}/>,
        cartContainer
    );

});

export const displayTimer = () => {
    let $timer = $('[data-timer="countdown"]');


    if ($timer.length) {
        var endCount = $timer.data('end'),
            nowServer = $timer.data('tare');

        var pattern = /(\d{4})\/(\d{2})\/(\d{2}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/;
        var dtNowNavigator = new Date();
        var dtNowServer = new Date(nowServer.replace(pattern, '$2/$3/$1 $4:$5:$6'));
        var dtEndServer = new Date(endCount.replace(pattern, '$2/$3/$1 $4:$5:$6'));

        var difference = dtNowServer - dtNowNavigator;
        var dtEndNavigator = new Date(dtEndServer - difference);

        var $modal = $('[data-timer="modal"]');

        var modalCartExpired = $('#modalCartExpired');

        modalCartExpired.on('hidden.bs.modal', function (e) {

            var redirectPath = $modal.attr('data-timer-path');
            if (redirectPath && redirectPath.length) {
                window.location = redirectPath;
            } else {
                window.location.reload();
            }

        });


        $timer.syotimer({
            year: dtEndNavigator.getFullYear(),
            month: dtEndNavigator.getMonth() + 1,
            day: dtEndNavigator.getDate(),
            hour: dtEndNavigator.getHours(),
            minute: dtEndNavigator.getMinutes(),
            second: dtEndNavigator.getSeconds(),
            layout: 'ms',
            dayVisible: false,
            afterDeadline: function (timerBlock) {

                $('[data-timer="modal"]').modal('show')

            }
        });

    }
}

export const fetchCart = async () => {
    const url = '/api/cart';
    const response = await fetch(url);
    let cart = null;

    if (response.ok) {
        cart = await response.json();
    }

    return cart;
}
