import React, {useState, useEffect, useRef} from "react";
import ResumeSeat from "../../../../bleucitron/react/front/resume/components/ResumeSeat";
import MainContainer from "../../../../common/react/map/containers/MainContainer";
import NumberFormat from "react-number-format";
import $ from "jquery";



// funct component qui récupère la props session ID
const ResumedPlan = (props) => {

    const childRef = useRef();

    const [total, setTotal] = useState(0);

    const [resumeSeats, setResumeSeats] = useState([]);

    const [isCategoryOpen, setCategoryOpen] = useState(false);

    const [detailPanelStyle, setDetailPanelStyle] = useState("");

    const [chevronDirection, setChevronDirection] = useState("up");


    //
    const handleSelectedSeats = (selectedSeats, addedSeat) => {

        setResumeSeats([...selectedSeats]);

        setTotal(total + parseFloat(addedSeat.price));

    }


    //
    const handleCloseModal = () => {

        $("#availabilityMap").modal("hide");
        // $('body').removeClass('modal-open');
        $('.modal-backdrop').remove();
    }


    //
    const handleClickCategories = () => {

        setCategoryOpen(!isCategoryOpen);

    }


    //
    const closeCategories = () => {

        setCategoryOpen(false);

    }


    //
    const book = () => {

        childRef.current.showLoader();
        childRef.current.book();

    }


    //
    const handleUnselectSeat = (seatId) => {

        let updatedSeats = [];
        let updatedTotal = 0;
        resumeSeats.forEach((seat, index) => {

            if (seat.id !== seatId) {
                updatedSeats.push(seat);
                updatedTotal = updatedTotal + parseInt(seat.price);
            }

        });

        setResumeSeats(updatedSeats);
        setTotal(updatedTotal);


        childRef.current.unselect(seatId);
    }

    const toggleDetailPanel = () => {

        setDetailPanelStyle(chevronDirection === "up" ? "opened" : "");
        setChevronDirection(chevronDirection === "up" ? "down" : "up");
    }


    let lines = [];
    resumeSeats.forEach((seat, index) => {
        lines.push(
            <ResumeSeat key={seat.id} seat={seat} onDelete={handleUnselectSeat} />
        );

    });


    return (

        <div className={"plandyn-ACP "+(isCategoryOpen ? ' filtering' : '')} style={{width: '100%', height: '100%'}}>
                <div className={"modal-dialog modal-event-plan modal-fullscreen"} role="document">
                    <div className={"modal-content"}>
                        <div className={"modal-body"}>

                            <div>

                                <div className={"row flexbox"}>

                                    <div className={"selector _mainPlandyn col-xs-12 col-md-9"}>
                                        <section className={"panel panel-default"}>
                                            <div className={"modal-header panel-bar toggle collapsed"}>

                                                <h3 className={"panel-title category"} data-toggle="collapse">
                                            <span className={"icon toggle-icon pull-left"}>
                                                <i className={"fonticon fonticon-search fonticon24"}></i>
                                            </span>
                                                    <a className={"text"} style={{'fontWeight' : 'bold'}}>Choix sur plan</a>
                                                </h3>

                                            </div>
                                            <div onClick={closeCategories} className={"panel-body"} id="map-js" style={{width: '100%', height: '100%'}}>
                                                <MainContainer
                                                    ref={childRef}
                                                    sessionId={props.sessionId}
                                                    sessionList={props.sessionList}
                                                    packId={props.packId}
                                                    cartId={props.cartId}
                                                    idwl={props.wlId}
                                                    maxSeats={props.maxSeats}
                                                    holders={props.holders}
                                                    autoload={props.autoload}
                                                    onBooked={props.onBooked}
                                                    onSeatSelected={handleSelectedSeats}
                                                    inBox={true}
                                                    fullVisible={true}
                                                />
                                            </div>
                                        </section>
                                    </div>

                                    <div className={"_panierPlandyn selection col-xs-12 col-md-3 " + detailPanelStyle}>
                                        <section className={"panel panel-default"}>
                                            <div className={"modal-header panel-bar toggle"}>
                                                <span className={"icon pull-left toggle-icon"} style={{'marginRight': '15px'}}><i
                                                    className={"fas fa-map-pin"}></i></span>
                                                <h3 className={"panel-title heading-box"}>
                                                    <div className={"total pull-left"}><span
                                                        className={"text"} style={{'fontWeight' : 'bold'}}>{resumeSeats.length} place{resumeSeats.length > 1 ? 's' : ''}</span></div>
                                                    <div className={"price pull-right"}><span
                                                        className={"text"}><NumberFormat displayType={'text'}
                                                                                         decimalScale={2}
                                                                                         fixedDecimalScale={true}
                                                                                         decimalSeparator={","}
                                                                                         value={total}
                                                                                         suffix={"€"}/></span>
                                                    </div>
                                                </h3>
                                            </div>
                                            <div onClick={closeCategories} className={"panel-collapse panierPanel"}>
                                                <div className={"text-center pt-15 pb-15 pl-15 pr-15"}>
                                                    <div>Cliquez sur le plan pour choisir vos places, utilisez la
                                                        molette
                                                        pour zoomer.
                                                    </div>
                                                    <div className="mt-10">Votre panier sera validé à
                                                        l'étape
                                                        suivante
                                                    </div>
                                                </div>
                                                <div className={"panel-body"} id="map-resume"
                                                     style={{touchAction: 'pan-y', userSelect: 'none'}}>
                                                    {lines}
                                                </div>
                                            </div>
                                            <div className={"modal-footer"}>
                                                <div className={"row flexbox"} style={{'textAlign' : 'center'}}>
                                                    <div className={"col-xs-"+(resumeSeats.length ? '6' : '12')}>
                                                        <a className={"btn btn-lg btn-inverse _cancelLink"} onClick={handleCloseModal}><span
                                                            className={"text"}>Annuler</span></a>
                                                    </div>
                                                    {resumeSeats.length ?
                                                        <div className={"col-xs-6"}>
                                                            <a className={"btn btn secondary btn-lg _validateLink"} onClick={book}><span
                                                                className={"text"}>Réserver</span></a>
                                                        </div>
                                                        : ''
                                                    }
                                                </div>
                                            </div>
                                        </section>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                </div>
        </div>

    );


}

export default ResumedPlan;

ResumedPlan.defaultProps = {
    autoload: false,
}
